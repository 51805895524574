<template>
  <section class="edit-category">
    
    <div class="row">
      <div class="col-lg-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12">
                <h3 class="page-title float-left">Edit Invoice</h3>

                <router-link class="float-right" to="/settings/invoice-list">
                  <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                </router-link>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="card">
                <div class="card-body">
                  <form class="pt-2" @submit.prevent="editInvoice">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Name</label>
                              <input type="text" v-model="invoice.name" class="form-control" />
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Currency Format</label>
                              <select class="custom-select form-control" v-model="invoice.currency_format">
                                <option selected>Select Currency Format</option>
                                <option value="{SYMBOL}{VALUE}"><span v-if="invoice.currency_symbol && invoice.currency_code">{{ invoice.currency_symbol }}&nbsp;{{
                                    invoice.currency_code
                                }}</span>
                                  <span v-else>{VALUE}{SYMBOL}</span>
                                </option>
                                <option value="{VALUE}{SYMBOL}"><span v-if="invoice.currency_symbol && invoice.currency_code">{{ invoice.currency_code }}&nbsp;{{
                                    invoice.currency_symbol
                                }}</span>
                                  <span v-else>{SYMBOL}{VALUE}</span>
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Image</label>
                              <vue-dropzone ref="myVueDropzone" id="thumbVideo " :options="dropzoneOptions"
                                :useCustomSlot="true" v-on:vdropzone-success="success" @vdropzone-sending="sendImages">
                                <div class="dropzone-custom-content">
                                  <div class="dropzone-custom-content">
                                    <div v-if="info">
                                      <div class="image-size-dropzone">
                                        <img :src="`${imageApiURL}/${info.thumb_s}`" class="show-img img-fluid" />
                                      </div>
                                      <div class="subtitle pt-1 pb-0">
                                        <span> Change </span>
                                        <span style="color: #eb2027"> Browse</span>
                                      </div>
                                    </div>
                                    <div v-if="!info" class="pt-2">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="43" height="43" viewBox="0 0 24 24"
                                        fill="transparent" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-upload" color="">
                                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                        <polyline points="17 8 12 3 7 8"></polyline>
                                        <line x1="12" y1="3" x2="12" y2="15"></line>
                                      </svg>
                                      <div class="subtitle pt-1 pb-2">
                                        <span> Drop File or </span>
                                        <span style="color: #eb2027"> Browse</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </vue-dropzone>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Currency Code</label>
                              <select class="custom-select form-control" v-model="invoice.currency_code">
                                <option v-for="currenciesList in currenciesList" :key="currenciesList.id"
                                  :value="currenciesList.name">
                                  {{ currenciesList.name }}
                                </option>
                              </select>
                              <!-- <multiselect v-model="currency_symbol" :options="currenciesList" track-by="name" label="name">
                              </multiselect> -->
                            </div>
                          </div>
                          <div class="col-12">

                            <div class="form-group">
                              <label for="name">Currency Symbol</label>
                              <select class="custom-select form-control" v-model="invoice.currency_symbol">
                                <option v-for="currenciesList in currenciesList" :key="currenciesList.id"
                                  :value="currenciesList.symbol">
                                  {{ currenciesList.symbol }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group">
                              <label for="name">Notes</label>
                              <textarea class="form-control" v-model="invoice.notes" rows="5"></textarea>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group mb-2 pb-1">
                              <label for="name" class="pr-2 pt-1">Active </label>
                              <label class="switch">
                                <input type="checkbox" v-model="invoice.active" />
                                <span class="slider round"></span> </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <div class="form-group float-right mb-0  pt-3">
                          <button class="btn btn btn-add btn-primary">Update</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
const imageApiURL = process.env.VUE_APP_IMAGE_URL;
import { setingsApi } from "../../../api";

import Multiselect from "vue-multiselect";
const mediaApi = process.env.VUE_APP_MEDIA_SERVICE;
export default {
  name: "add-invoice",
  components: {
    
    Multiselect,
    vueDropzone: vue2Dropzone,
  },
  data() {
    return {
      
      invoice: "",
      info: "",
      imageApiURL,
      options: [],
      dropzoneOptions: {
        url: `${mediaApiURL}/file/upload`,
        maxFilesize: 10,
      },
      currencies: null,
      currency_symbol: null,
      currenciesList: [],
      submitted: false,
    };
  },
  mounted() {
    this.getcurrencies();
    this.listById()
  },
  methods: {
    async getcurrencies() {
      
      const { data } = await setingsApi.currencies();
      this.currenciesList = data.data.data;
      this.currency_symbol = this.currenciesList.currency_code
      // console.log(this.currenciesList);
      
    },
    async listById() {
      
      const { data } = await setingsApi.invoiceServicesById(this.$route.params.id);
      this.invoice = data.data.data;
      // this.invoice.currency_code = this.currencies.name
      if (this.invoice.image_id) {
        this.banner();
      }
      // console.log(this.invoice, "this.invoice");
      
    },
    banner() {
      
      axios.get(`${mediaApi}/files/${this.invoice.image_id}`).then((response) => {
        this.info = response.data.data[0];
        
      });
    },
    sendImages(file, xhr, formData) {
      let products = "products";
      xhr.setRequestHeader("Header", "");
      formData.append("folder", products);
    },

    success(file, response) {
      this.invoice.image_id = response.data.id;
      if (response.status == "success") {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: "Updated successfully",
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: "The given data was invalid",
        });
      }
    },
    async editInvoice() {

      this.submitted = true;
       for (var key in this.invoice) {
        if (this.invoice.hasOwnProperty(key)) {
          var val = this.invoice[key];
          if (val === null) {
            delete this.invoice[key];
          }
        }
       }
    

      
      const data = await setingsApi.updateInvoice(this.$route.params.id, {
        ...this.invoice,
      });
      

      this.$router.push("/settings/invoice-list");
      if (data.status == 200) {
        this.$swal({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          type: "success",
          title: " ",
          text: data.messages,
        });
      } else {
        this.$swal({
          type: "error",
          title: "Oops...",
          text: data.messages,
        });
      }
      // alert("SUCCESS!! :-)\n\n" + JSON.stringify(this.invoice));
    },
  },
};
</script>
<style scoped>
.dropzone {
  padding: 29px 20px !important;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css">
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css">
</style>